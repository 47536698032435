<template>
  <div>
    <div class="cmd-group">
      <div class="form-group">
        <b>{{ acquisitionNumberTitle[acqType] }}</b>
        <input type="text" class="vInput" v-model="cmd.acqNumber" />
      </div>
      <div class="form-group">
        <b>{{ acquisitionDateTitle[acqType] }}</b>
        <date-picker
          v-model="cmd.acquisitionDate"
          type="date"
          format="DD-MM-YYYY"
          valueType="date"
          popup-class="bigger-index"
        />
      </div>
    </div>
    <div class="cmd-group">
      <div class="form-group noMB">
        <div>
          <b>CUI furnizor</b>
        </div>
        <div class="RForm">
          <input
            type="text"
            class="vInput"
            v-model="cmd.institutionProviderCui"
            :disabled="verbalProcess ? true : false"
            :class="{ 'form-disabled': verbalProcess ? true : false }"
          />
          <i @click="getByCui" class="fas fa-search"></i>
        </div>
      </div>
      <div class="form-group">
        <b>Nume furnizor</b>
        <input
          type="text"
          class="vInput form-disabled"
          v-model="cmd.institutionProviderName"
          :disabled="true"
        />
      </div>
    </div>

    <div class="cmd-group">
      <div class="form-group">
        <b>Valoare totală fără TVA</b>

        <CustomInput
          v-model="cmd.totalValueWithoutTva"
          :disabled="verbalProcess ? true : false"
        ></CustomInput>
      </div>
      <div class="form-group">
        <b>Cotă TVA</b>

        <input
          min="1"
          max="100"
          type="text"
          class="vInput procent"
          :class="{ 'form-disabled': verbalProcess ? true : false }"
          v-model="cmd.tvaValue"
          @input="updateTva"
          :disabled="verbalProcess ? true : false"
        />
      </div>
    </div>

    <div class="cmd-group">
      <div class="form-group">
        <b>Criteriul de atribuire</b>

        <v-select
          v-model="cmd.attributionCriteria"
          :options="[
            'Preţul cel mai scăzut',
            'Costul cel mai scăzut',
            'Cel mai bun raport calitate-preţ',
            'Cel mai bun raport calitate-cost',
          ]"
          :disabled="verbalProcess || announcement ? true : false"
        >
        </v-select>
      </div>
      <div class="form-group">
        <b>Valoare totală cu TVA</b>
        <CustomInput
          v-model="cmd.totalValueWithTva"
          :disabled="true"
        ></CustomInput>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/mixins/DatePicker.js";
import CustomInput from "@/components/CustomInput";
import { INSTITUTION_PROVIDERS } from "../api";

export default {
  mixins: [DatePicker],

  components: { CustomInput },

  props: {
    initial: {
      type: Object,
      default: () => null,
    },
    acqType: {
      type: String,
      required: true,
    },
    verbalProcess: { type: Object, default: () => null },
    announcement: { type: Object, default: () => null },
  },

  data() {
    return {
      cmd: {
        acqNumber: "",
        acquisitionDate: "",
        institutionProviderCui: "",
        institutionProviderName: "",
        totalValueWithoutTva: "",
        tvaValue: "",
        totalValueWithTva: "",
        attributionCriteria: "",
      },
    };
  },

  watch: {
    cmd: {
      handler(val) {
        val.totalValueWithTva =
          (Number(this.removePercentage(val.tvaValue)) / 100) *
            Number(val.totalValueWithoutTva) +
            Number(val.totalValueWithoutTva) ?? 0;

        this.$emit("input", {
          ...val,
          tvaValue: this.removePercentage(val.tvaValue),
        });
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },

  methods: {
    getByCui() {
      const error = (msg) => {
        this.$toastr.e(msg || "Furnizorul nu a fost gasit.");
        this.cmd.institutionProviderName = "";
        this.setLoad(false);
      };

      this.setLoad(true);

      INSTITUTION_PROVIDERS.getByCui(this.cmd.institutionProviderCui)
        .then((res) => {
          console.log(res);
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.cmd.institutionProviderName = res.data.name;
            this.setLoad(false);
          } else {
            error(res?.data?.response || null);
          }
        })
        .catch(error);
    },

    updateTva(event) {
      if (event.inputType == "deleteContentBackward" && this.cmd.tvaValue) {
        this.cmd.tvaValue = this.cmd.tvaValue.slice(0, -1);
        this.cmd.tvaValue += "%";
        return;
      }

      event.target.value = this.removePercentage(event.target.value);

      this.cmd.tvaValue = event.target.value.match(/^\d+\.?\d{0,2}$/);

      if (this.cmd.tvaValue) {
        this.cmd.tvaValue += "%";
      }
    },

    removePercentage(val) {
      if (!val) return "";
      return val.replace("%", "");
    },

    initCmd() {
      if (this.verbalProcess) {
        let minVal = this.verbalProcess.verbalProcessInstitutionProvider[0];

        for (
          let i = 1;
          i < this.verbalProcess.verbalProcessInstitutionProvider.length;
          i++
        ) {
          const pv = this.verbalProcess.verbalProcessInstitutionProvider[i];

          if (
            Number(pv.totalValueWithoutTva) <
            Number(minVal.totalValueWithoutTva)
          ) {
            minVal = pv;
          }
        }

        this.cmd = {
          acqNumber: "",
          acquisitionDate: "",
          institutionProviderCui: minVal.institutionProvider.cui,
          institutionProviderName: minVal.institutionProvider.name,
          totalValueWithoutTva: minVal.totalValueWithoutTva,
          tvaValue: minVal.tvaValue + "%",
          totalValueWithTva: minVal.totalValueWithTva,
          attributionCriteria: this.verbalProcess.attributionCriteria,
        };
      } else if (this.announcement) {
        this.cmd.attributionCriteria = this.announcement.attributionCriteria;
      }
    },
  },

  created() {
    this.initCmd();

    if (this.initial) {
      this.cmd = {
        ...this.initial,
        acquisitionDate: new Date(this.initial.acquisitionDate),
        tvaValue: this.initial.tvaValue + "%",
        institutionProviderName: this.initial.institutionProvider.name,
        institutionProviderCui: this.initial.institutionProvider.cui,
      };
    }
  },
};
</script>
<style lang="scss">
.cmd-group {
  display: flex;
  gap: 5px;
  .form-group {
    flex: 1;
  }
}
</style>
