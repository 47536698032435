<template>
  <input
    v-model="val"
    type="text"
    class="vInput"
    :class="disabled ? 'form-disabled' : ''"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    min: {
      type: [String, Number],
      default: "",
    },
    max: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    floatLength: {
      type: Number,
      default: 2,
    },
  },

  watch: {
    val(y) {
      this.emit();
    },
    value(val) {
      this.val = this.formatNumber(val, {
        maximumFractionDigits: this.floatLength,
      });
    },
  },

  methods: {
    emit() {
      if (this.disabled) return;
      let returnVal = this.val.replace(/\./g, "").replace(/,/g, ".");
      if (returnVal) {
        returnVal = parseFloat(returnVal);
      } else {
        returnVal = 0;
      }
      this.$emit("input", returnVal);
    },
  },

  data() {
    return {
      val: this.formatNumber(this.value, {
        maximumFractionDigits: this.floatLength,
      }),
    };
  },

  created() {
    this.emit();
  },
};
</script>
